import React, { useState, useEffect, useRef } from "react";

import { Link, useParams } from "react-router-dom";

import { messagesNew, messagesGet, callsGetById } from "./idb";
import Messages from "./Messages";
import Actions from "./Actions";
import Questions from "./Questions";

function CallDetails() {
	const chatContainerRef = useRef(null);
	const inputRef = useRef(null);
	const { id } = useParams();
	const [callData, setCallData] = useState({});
	const [newMessage, setNewMessage] = useState("");
	const [messages, setMessages] = useState([]);
	const [hasUserScrolled, setHasUserScrolled] = useState(false);
	const [questionState, setQuestionState] = useState(false);
	const [actionState, setActionState] = useState(false);
	const [questions, setQuestions] = useState({});
	const [actions, setActions] = useState({});

	useEffect(() => {
		fetchMessages();
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	useEffect(() => {
		const fetchCallData = async () => {
			const data = await callsGetById(id);
			setCallData(data);
		};
		fetchCallData();
	}, [id]);

	useEffect(() => {
		if (newMessage[0] === "?") {
			setQuestionState(true);
		} else {
			setQuestionState(false);
		}
		if (newMessage[0] === "!") {
			setActionState(true);
		} else {
			setActionState(false);
		}
	}, [newMessage]);

	const handleScroll = () => {
		const el = chatContainerRef.current;
		const isNearBottom = el.scrollHeight - el.scrollTop - el.clientHeight < 10;

		// Update the hasUserScrolled state based on scroll position
		if (!isNearBottom) {
			setHasUserScrolled(true);
		} else {
			setHasUserScrolled(false);
		}
	};

	const fetchMessages = async () => {
		const data = await messagesGet(id);
		setMessages(data);
		for (const message of data) {
			processFetchMessages(message);
		}
	};

	function extractIdFromMessage(str) {
		const match = str.match(/^(?:\?\?|!!)(\d+)/);
		return match ? match[1] : null;
	}

	const processFetchMessages = async (message) => {
		if (message.data.message.startsWith("??")) {
			const reply = message.data.message.replace(/^(\?\?\d+\s)/, "");
			const questionId = extractIdFromMessage(message.data.message);
			const q = questions;
			if (!q[questionId]) {
				q[questionId] = {};
			}
			q[questionId]["reply"] = reply;
			setQuestions(q);
		} else if (message.data.message[0] === "?") {
			const questionMessage = message.data.message.slice(1);
			const questionId = message.id;
			const q = questions;
			if (!q[questionId]) {
				q[questionId] = {};
			}
			q[questionId]["msg"] = questionMessage;
			setQuestions(q);
		}
		if (message.data.message.startsWith("!!")) {
			const id = extractIdFromMessage(message.data.message);
			const a = actions;
			if (!a[id]) {
				a[id] = {};
			}

			if (/^!!\d+x$/.test(message.data.message)) {
				a[id]["removed"] = true;
			} else {
				const note = message.data.message.replace(/^(!!\d+\s)/, "");
				if (!a[id]["notes"]) {
					a[id]["notes"] = {};
				}
				a[id]["notes"][message.id] = note;
			}
			setActions(a);
		} else if (message.data.message[0] === "!") {
			const msg = message.data.message.slice(1);
			const id = message.id;
			const a = actions;
			if (!a[id]) {
				a[id] = {};
			}
			a[id]["msg"] = msg;
			setActions(a);
		}
	};

	const handleNewMessageChange = (e) => {
		if (e.keyCode === 13 && newMessage.length > 0) {
			submitNewMessage();
			setNewMessage("");
		}
	};

	const processNewMessage = (e) => {
		setNewMessage(e.target.value);
	};

	const submitNewMessage = () => {
		const payload = { date: new Date(), message: newMessage };
		messagesNew(id, payload);
		fetchMessages();
		const el = chatContainerRef.current;
		if (!hasUserScrolled) {
			el.scrollTop = el.scrollHeight;
		}
	};

	return (
		<div className="bg-slate-400 h-screen p-3">
			<div className="flex h-full">
				<div className="flex-1 bg-slate-50 flex flex-col">
					<div className="p-2 text-lg font-semibold text-slate-500 border-b border-b-slate-200">
						<Link to="/" className="text-sm  text-blue-500">
							Back
						</Link>{" "}
						{callData.data?.title}
					</div>
					<div className="flex-grow p-2 overflow-y-auto" ref={chatContainerRef} onScroll={handleScroll}>
						<Messages messages={messages} />
					</div>
					<div>
						<input
							ref={inputRef}
							type="text"
							className={`w-full border-0 focus:ring-0 ${
								questionState ? "bg-rose-200" : actionState ? "bg-sky-200" : "bg-slate-200"
							}`}
							onKeyDown={handleNewMessageChange}
							value={newMessage}
							onChange={processNewMessage}
							placeholder="   Type a message..."
						/>
					</div>
				</div>

				<div className="flex-1 flex flex-col">
					<div className="flex-1 p-5 overflow-y-auto ml-1 bg-rose-50">
						<Questions questions={questions} />
					</div>

					<div className="flex-1 p-5 overflow-y-auto ml-1 bg-sky-50">
						<Actions actions={actions} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default CallDetails;
