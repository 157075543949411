import { format, isSameDay } from "date-fns";

const cleanMessage = (msg) => {
	if (msg.startsWith("***")) {
		return msg.slice(3);
	}
	return msg;
};

const isFirstMessageOfDay = (currentIndex, messages) => {
	if (currentIndex === 0) return true; // If it's the first message in the list, it's always true

	const currentMessageDate = messages[currentIndex].data.date;
	const previousMessageDate = messages[currentIndex - 1].data.date;

	return !isSameDay(currentMessageDate, previousMessageDate);
};

export default function Messages({ messages }) {
	const formatMessage = (messageData, index) => {
		const { date, message } = messageData;
		let textSize = "text-md";
		if (message.startsWith("***")) {
			textSize = "text-lg font-semibold";
		}

		return (
			<>
				{isFirstMessageOfDay(index, messages) && (
					<div className="text-slate-400 text-sm my-2">{format(date, "MMMM dd")}</div>
				)}
				<span className="text-slate-300 text-sm mr-2">{format(date, "HH:mm")}</span>
				<span className={textSize}>{cleanMessage(message).trim()}</span>
			</>
		);
	};
	return (
		<ul className="mb-4">
			{messages.map((item, index) => (
				<li key={item.id} className="text-sm text-slate-500">
					{formatMessage(item.data, index)}
				</li>
			))}
		</ul>
	);
}
