import { openDB } from "idb";

const DB_NAME = "stenotyper";
const DB_VERSION = 1;
const TABLE_CALLS = "calls";
const TABLE_MESSAGES = "messages";
const TABLE_MESSAGES_INDEX_CALLSID = "messages_index_callsId";
const TABLE_QUESTIONS = "questions";
const TABLE_QUESTIONS_INDEX_CALLSID = "questions_index_callsId";
const TABLE_ACTIONS = "actions";
const TABLE_ACTIONS_INDEX_CALLSID = "actions_index_callsId";

async function initializeDB() {
	return await openDB(DB_NAME, DB_VERSION, {
		upgrade(db) {
			if (!db.objectStoreNames.contains(TABLE_CALLS)) {
				db.createObjectStore(TABLE_CALLS, { keyPath: "id", autoIncrement: true });
			}
			if (!db.objectStoreNames.contains(TABLE_MESSAGES)) {
				const tblMessages = db.createObjectStore(TABLE_MESSAGES, { keyPath: "id", autoIncrement: true });
				tblMessages.createIndex(TABLE_MESSAGES_INDEX_CALLSID, "callsId", { unique: false });
			}
			if (!db.objectStoreNames.contains(TABLE_QUESTIONS)) {
				const tblQuestions = db.createObjectStore(TABLE_QUESTIONS, { keyPath: "id", autoIncrement: true });
				tblQuestions.createIndex(TABLE_QUESTIONS_INDEX_CALLSID, "callsId", { unique: false });
			}
			if (!db.objectStoreNames.contains(TABLE_ACTIONS)) {
				const tblActions = db.createObjectStore(TABLE_ACTIONS, { keyPath: "id", autoIncrement: true });
				tblActions.createIndex(TABLE_ACTIONS_INDEX_CALLSID, "callsId", { unique: false });
			}
		},
	});
}

const dbPromise = initializeDB();

export async function callsAddNew(data) {
	const db = await dbPromise;
	const tx = db.transaction(TABLE_CALLS, "readwrite");
	const store = tx.objectStore(TABLE_CALLS);
	await store.add({ data });
	await tx.complete;
}

export async function callsGetAll() {
	const db = await dbPromise;
	const tx = db.transaction(TABLE_CALLS, "readonly");
	const store = tx.objectStore(TABLE_CALLS);
	return store.getAll();
}

export async function callsGetById(id) {
	const db = await dbPromise;
	const tx = db.transaction(TABLE_CALLS, "readonly");
	const store = tx.objectStore(TABLE_CALLS);
	return store.get(parseInt(id, 10));
}

export async function messagesNew(callsId, data) {
	const db = await dbPromise;
	const tx = db.transaction(TABLE_MESSAGES, "readwrite");
	const store = tx.objectStore(TABLE_MESSAGES);
	await store.add({ callsId: parseInt(callsId, 10), data });
	await tx.complete;
}

export async function messagesGet(callsId) {
	const db = await dbPromise;
	const tx = db.transaction(TABLE_MESSAGES, "readonly");
	const store = tx.objectStore(TABLE_MESSAGES);
	const index = store.index(TABLE_MESSAGES_INDEX_CALLSID);
	return index.getAll(parseInt(callsId, 10));
}
