import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Calls from "./Calls";
import CallDetails from "./CallDetails";

export default function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Calls />} />
				<Route path="/call-details/:id" element={<CallDetails />} />
			</Routes>
		</Router>
	);
}
