import React, { useState } from "react";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Questions({ questions }) {
	const [showAnswered, setShowAnswered] = useState(false);
	return (
		<>
			<div className="flex justify-between items-center">
				<div className="text-lg font-semibold text-slate-500">Questions</div>
				<Switch.Group as="div" className="flex items-center">
					<Switch.Label as="span" className="text-sm">
						<span className={classNames(showAnswered ? "text-slate-500" : "text-slate-400", "text-sm text-slate-400")}>
							Show Answered
						</span>
					</Switch.Label>
					<Switch
						checked={showAnswered}
						onChange={setShowAnswered}
						className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full "
					>
						<span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md " />
						<span
							aria-hidden="true"
							className={classNames(
								showAnswered ? "bg-slate-500" : "bg-slate-200",
								"pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
							)}
						/>
						<span
							aria-hidden="true"
							className={classNames(
								showAnswered ? "translate-x-5" : "translate-x-0",
								"pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
							)}
						/>
					</Switch>
				</Switch.Group>
			</div>
			<ul className="mb-4 ml-2 divide-y divide-rose-200">
				{Object.keys(questions)
					.filter((id) => {
						if (showAnswered) return true; // If showAnswered is true, show all questions
						return !questions[id]["reply"]; // Otherwise, only show unanswered questions
					})
					.map((id) => (
						<li key={id} className="text-slate-700 py-1.5">
							<span className={questions[id]["reply"] && "line-through text-slate-400"}>
								#{id}: {questions[id]["msg"]}
							</span>
							{questions[id]["reply"] && (
								<span className="text-slate-400">
									<br />
									-- {questions[id]["reply"]}
								</span>
							)}
						</li>
					))}
			</ul>
		</>
	);
}
