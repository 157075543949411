import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import { callsAddNew, callsGetAll } from "./idb";

export default function Calls() {
	const [newCallTitle, setNewCallTitle] = useState("");
	const [calls, setCalls] = useState([]);

	useEffect(() => {
		fetchCalls();
	}, []);

	const fetchCalls = async () => {
		const data = await callsGetAll();
		setCalls(data);
	};

	const handleCreateCallClick = async () => {
		const data = { title: newCallTitle, date: new Date() };
		await callsAddNew(data);
		setNewCallTitle("");
		fetchCalls();
	};

	return (
		<div className="p-4">
			<p className="text-2xl text-slate-600 font-semibold">StenoTyper</p>
			<div className="flex items-center space-x-4 p-4 bg-slate-50">
				<label htmlFor="callTitle" className="block text-sm font-medium leading-6 text-gray-900">
					Create New Call
				</label>
				<div className="mt-2">
					<input
						type="text"
						id="callTitle"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
						value={newCallTitle}
						onChange={(e) => setNewCallTitle(e.target.value)}
						placeholder="Call Title"
					/>
				</div>

				<button
					type="button"
					className="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					onClick={handleCreateCallClick}
				>
					Create Call
				</button>
			</div>
			<ul className="divide-y divide-gray-200">
				{calls.map((item) => (
					<li key={item.id} className="py-4">
						<Link to={`/call-details/${item.id}`} className="block hover:text-blue-600">
							<p>
								{format(item.data.date, "yyyy-MM-dd HH:mm:ss")}: {item.data.title}
							</p>
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
}

/*

*/
